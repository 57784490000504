
import { Nav } from './components/nav'
import { Module_05_Events } from './modules/module-05-events'
import { Contact } from './pages/contact'
import { ArchiveLocations } from './pages/archive-locations'
import { ArchiveEventsPromotions } from './pages/archive-events-promotions'

// import { PopUp } from './components/popup'

class App {
  constructor() {
    const nav = new Nav;

    if(document.querySelector('.template-contact .drop_click')) {
      const contact = new Contact;
    }

    if(document.querySelector('.module-05-events')) {
      const module_05_events = new Module_05_Events;
    }

    if(document.querySelector('.template-archive-locations .filter_form')) {
      const archiveLocations = new ArchiveLocations;
    }

    if(document.querySelector('.template-archive-events-promotions .filter_form')) {
      const archiveEventsPromotions = new ArchiveEventsPromotions;
    }
  }
}

window.onload = function() {
  let app = new App();
};

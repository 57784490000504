import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class ArchiveLocations {
  constructor() {
    let filter = 'all';
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.archive = document.querySelector('.template-archive-locations .archive_container .archive');
        cache.select = document.querySelector('.template-archive-locations .filter_form select');
    };

    let registerEvents = () => {
        cache.select.addEventListener('change', filterPosts);
    };

    let filterPosts = function() {
        const value = cache.select.value;
        if(filter !== value) {
            filter = value;
            const oldheight = cache.archive.offsetHeight;
            cache.archive.style.height = cache.archive.offsetHeight+"px";
            const visibles_cards = cache.archive.querySelectorAll('.card_loc.visible');
            for (let i = 0; i < visibles_cards.length; i++) {
                anime({
                    targets: visibles_cards[i],
                    opacity: [1,0],
                    translateY: ['0%','20%'],
                    duration: 300,
                    delay: 100 * i,
                    easing: 'easeOutSine',
                    complete: function() {
                        if(i == visibles_cards.length-1) {
                            const all_cards = cache.archive.querySelectorAll('.card_loc');
                            for (let ii = 0; ii < all_cards.length; ii++) {
                               const card = all_cards[ii];
                               if(card.classList.contains('filter_'+value)) {
                                    if(!card.classList.contains('visible')) {
                                        card.classList.add('visible');
                                    }
                               }
                               else {
                                    if(card.classList.contains('visible')) {
                                        card.classList.remove('visible');
                                    }
                               }

                               if(ii == all_cards.length-1) {
                                    const cards = cache.archive.querySelectorAll('.card_loc.visible');
                                    for (let i = 0; i < cards.length; i++) {
                                        anime({
                                            targets: cards[i],
                                            opacity: [0,1],
                                            translateY: ['20%','0%'],
                                            duration: 300,
                                            delay: 100 * i,
                                            easing: 'easeOutSine',
                                        });
                                    }


                                    cache.archive.style.height = "auto";
                                    const height = cache.archive.offsetHeight;
                                    cache.archive.style.height = oldheight+"px";
                                    anime({
                                        targets: cache.archive,
                                        height: [oldheight,height],
                                        duration: 400,
                                        easing: 'easeOutSine',
                                    });
                               }
                            }
                        }
                    }
                });
            }
        }
    }

    initFunc();
  };
}
export { ArchiveLocations }
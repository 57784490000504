import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Nav {
  constructor() {
    let scrollPos;
    let btnVisible = false;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.header = document.querySelector('header');
      cache.navBtn = cache.header.querySelector('.nav-top .burger');
      cache.nav = cache.header.querySelector('.nav-menu');
      cache.navItem = cache.nav.querySelectorAll('.left .nav_item');
      cache.dogHat = cache.nav.querySelector('.dog .dog_hat');
    };

    let registerEvents = () => {
      cache.navBtn.addEventListener("click", openNav);
      window.addEventListener('scroll', scrollDetect);
      if(!globalVar.tablet.matches) {
        for (let i = 0; i < cache.navItem.length; i++) {
          cache.navItem[i].addEventListener("mouseenter", hoverNavItem);
        }
      }
    };

    let hoverNavItem = function() {
      this.removeEventListener("mouseenter", hoverNavItem);
      this.addEventListener("mouseleave", hoverNavItemOut);
      const icon = cache.nav.querySelector('.dog .'+this.dataset.icon);
      anime({
        targets: cache.dogHat,
        translateY: '-115%',
        duration: 200,
        easing: 'easeOutSine'
      });
      anime({
        targets: icon,
        translateY: [30, 0],
        scale:[0.5,1],
        opacity: [0,1],
        duration: 200,
        easing: 'easeOutSine'
      });
    }
    let hoverNavItemOut = function() {
      this.removeEventListener("mouseleave", hoverNavItemOut);
      this.addEventListener("mouseenter", hoverNavItem);
      const icon = cache.nav.querySelector('.dog .'+this.dataset.icon);
      anime({
        targets: cache.dogHat,
        translateY: 0,
        duration: 200,
        easing: 'easeOutSine'
      });
      anime({
        targets: icon,
        translateY: [0, 30],
        scale:[1,0.5],
        opacity: [1,0],
        duration: 200,
        easing: 'easeOutSine'
      });
    }



    let openNav = () => {
      cache.navBtn.removeEventListener("click", openNav);
      cache.navBtn.addEventListener("click", closeNav);
      cache.header.style.height = "100vh";
      cache.header.classList.add('open');
      document.querySelector('body').classList.add('overflow');
      document.querySelector('html').classList.add('overflow');
      utils.disableScroll();
      cache.nav.style.display = "flex";

      if(!btnVisible && !globalVar.tablet.matches) {
        const btn = cache.header.querySelectorAll('.btn_wrap .nav_btn');
        if(btn) {
          for (let i = 0; i < btn.length; i++) {
            btn[i].style.display = "flex";
            anime({
              targets: btn[i],
              opacity: [0,1],
              scale: [0.8, 1],
              duration: 300,
              delay: 100 * i,
              easing: 'easeOutQuad'
            });
          }
        }
      }
      anime({
        targets: cache.header,
        backgroundColor: "rgba(246, 241, 238, 1)",
        duration: 400,
        easing: 'easeOutSine',
      });

      for (let i = 0; i < cache.navItem.length; i++) {
        anime({
          targets: cache.navItem[i],
          opacity: [0,1],
          translateY: ['200%','0%'],
          duration: 400,
          delay: 200 + (40 * i),
          easing: 'easeOutSine',
        });
      }
      anime({
        targets: cache.nav.querySelector('.dog'),
        opacity: [0,1],
        scale: [0.9, 1],
        translateY: ['10%','0%'],
        duration: 400,
        delay: 300,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.nav.querySelector('.social_container'),
        opacity: [0,1],
        translateX: ['10%','0%'],
        duration: 400,
        delay: 600,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.nav.querySelector('.btn_wrap-mobile'),
        opacity: [0,1],
        translateY: ['10%','0%'],
        duration: 400,
        delay: 800,
        easing: 'easeOutSine',
      });
    }


    let closeNav = () => {
      cache.navBtn.removeEventListener("click", closeNav);
      cache.navBtn.addEventListener("click", openNav);
      cache.header.classList.remove('open');

      if(!btnVisible && !globalVar.tablet.matches) {
        const btn = cache.header.querySelectorAll('.btn_wrap .nav_btn');
        if(btn) {
          for (let i = 0; i < btn.length; i++) {
            btn[i].style.display = "flex";
            anime({
              targets: btn[i],
              opacity: [1,0],
              scale: [1, 0.8],
              duration: 300,
              delay: 100 * i,
              easing: 'easeOutQuad'
            });
          }
        }
      }

      for (let i = 0; i < cache.navItem.length; i++) {
        anime({
          targets: cache.navItem[i],
          opacity: [1,0],
          translateY: ['0%','200%'],
          duration: 400,
          delay: 200 + (40 * i),
          easing: 'easeOutSine',
        });
      }
      anime({
        targets: cache.nav.querySelector('.dog'),
        opacity: [1,0],
        scale: [1, 0.9],
        translateY: ['0%','10%'],
        duration: 400,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.header,
        backgroundColor: "rgba(246, 241, 238, 0)",
        duration: 400,
        delay: 200,
        easing: 'easeOutSine',
        complete: function() {
          cache.header.style.height = "auto";
          utils.enableScroll();
          cache.nav.style.display = "none";
          document.querySelector('body').classList.remove('overflow');
          document.querySelector('html').classList.remove('overflow');
        }
      });
      anime({
        targets: cache.nav.querySelector('.social_container'),
        opacity: [1,0],
        translateX: ['0%','10%'],
        duration: 400,
        easing: 'easeOutSine',
      });
      anime({
        targets: cache.nav.querySelector('.btn_wrap-mobile'),
        opacity: [1,0],
        translateY: ['0%','10%'],
        duration: 400,
        easing: 'easeOutSine',
      });
    }


    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;
      if(!up && (scrollPos > (window.innerHeight*0.5)) && !cache.header.classList.contains('up') && !btnVisible) {
        btnVisible = true;
        const btn = cache.header.querySelectorAll('.btn_wrap .nav_btn');
        if(btn && !globalVar.tablet.matches) {
          for (let i = 0; i < btn.length; i++) {
            btn[i].style.display = "flex";
            anime({
              targets: btn[i],
              opacity: [0,1],
              scale: [0.8, 1],
              duration: 300,
              delay: 100 * i,
              easing: 'easeOutQuad'
            });
          }
        }
      }
      if(!up && (scrollPos > window.innerHeight) && !cache.header.classList.contains('up')) {
        document.querySelector('body').classList.add('up');
        cache.header.classList.add('up');
        anime({
          targets: cache.header,
          translateY: ['0%', '-100%'],
          duration: 500,
          easing: 'easeOutQuad'
        });
      } else if(up && (scrollPos > window.innerHeight) && cache.header.classList.contains('up')) {
        document.querySelector('body').classList.remove('up');
        cache.header.classList.remove('up');
        anime({
          targets: cache.header,
          translateY: ['-100%', '0%'],
          duration: 500,
          easing: 'easeOutQuad',
        });
      }
    }

    initFunc();
  };
}
export { Nav }

import Hammer from '../libs/hammer'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Module_05_Events {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.events3 = document.querySelectorAll('.module-05-events .post_3');
      cache.events2 = document.querySelectorAll('.module-05-events .post_2');
    };

    let registerEvents = () => {
        if(document.querySelectorAll('.module-05-events .post_3')) {
            if(!globalVar.tablet.matches) {
                for (let i = 0; i < cache.events3.length; i++) {
                    cache.events3[i].querySelector('.card_1').addEventListener("mouseenter", hover3Card1);
                    cache.events3[i].querySelector('.card_2').addEventListener("mouseenter", hover3Card2);
                    cache.events3[i].querySelector('.card_3').addEventListener("mouseenter", hover3Card3);
                    anime({
                        targets: cache.events3[i].querySelector('.card_2'),
                        translateY: 35,
                        translateX: 30,
                        rotate: -6,
                        duration: 0,
                        easing: 'easeInOutSine'
                      });
                
                      anime({
                        targets: cache.events3[i].querySelector('.card_3'),
                        translateY: 35,
                        translateX: -30,
                        rotate: 6,
                        duration: 0,
                        easing: 'easeInOutSine'
                      });
                }
            } else {
                for (let i = 0; i < cache.events3.length; i++) {
                    let hammertime = new Hammer(cache.events3[i]);
                    hammertime.on('swipe', function(ev) {
                        if(ev.direction == 2) {
                            slideRight(cache.events3[i]);
                        }
                        if(ev.direction == 4) {
                            slideLeft(cache.events3[i]);
                        }
                    });
                }
            }
        }

        if(document.querySelectorAll('.module-05-events .post_2')) {
            if(!globalVar.tablet.matches) {
                for (let i = 0; i < cache.events2.length; i++) {
                    cache.events2[i].querySelector('.card_1').addEventListener("mouseenter", hover2Card1);
                    cache.events2[i].querySelector('.card_2').addEventListener("mouseenter", hover2Card2);
                    anime({
                        targets: cache.events2[i].querySelector('.card_1'),
                        translateX: -50,
                        rotate: -6,
                        duration: 0,
                        easing: 'easeInOutSine'
                      });
                
                      anime({
                        targets: cache.events2[i].querySelector('.card_2'),
                        translateX: 50,
                        rotate: 6,
                        duration: 0,
                        easing: 'easeInOutSine'
                      });
                }
            } else {
                for (let i = 0; i < cache.events2.length; i++) {
                    let hammertime = new Hammer(cache.events2[i]);
                    hammertime.on('swipe', function(ev) {
                        if(ev.direction == 2) {
                            slideRight(cache.events2[i]);
                        }
                        if(ev.direction == 4) {
                            slideLeft(cache.events2[i]);
                        }
                    });
                }
            }
        }

    };


    let hover3Card1 = function() {
      this.removeEventListener("mouseenter", hover3Card1);
      this.addEventListener("mouseleave", hover3Card1Out);

      const parent = this.closest('.events_container');
      const card1 = parent.querySelector('.card_1');
      const card2 = parent.querySelector('.card_2');
      const card3 = parent.querySelector('.card_3');

      anime({
        targets: card2,
        translateY: 35,
        translateX: 15,
        rotate: -8,
        duration: 400,
        easing: 'easeInOutSine'
      });

      anime({
        targets: card1,
        translateX: 0,
        rotate: 0,
        duration: 400,
        easing: 'easeInOutSine'
      });

      anime({
        targets: card3,
        translateY: 35,
        translateX: -15,
        rotate: 8,
        duration: 400,
        easing: 'easeInOutSine'
      });
    }

    let hover3Card1Out = function() {
        this.addEventListener("mouseenter", hover3Card1);
        this.removeEventListener("mouseleave", hover3Card1Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
        const card3 = parent.querySelector('.card_3');
  
        anime({
            targets: card2,
            translateY: 35,
            translateX: 30,
            rotate: -8,
            duration: 400,
            easing: 'easeInOutSine'
          });

          anime({
            targets: card1,
            translateX: 0,
            rotate: 0,
            duration: 400,
            easing: 'easeInOutSine'
          });
    
          anime({
            targets: card3,
            translateY: 35,
            translateX: -30,
            rotate: 8,
            duration: 400,
            easing: 'easeInOutSine'
          });
    }




    let hover3Card2 = function() {
        this.removeEventListener("mouseenter", hover3Card2);
        this.addEventListener("mouseleave", hover3Card2Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
        const card3 = parent.querySelector('.card_3');
  
        anime({
          targets: card2,
          translateY: 0,
          translateX: 30,
          rotate: 0,
          duration: 400,
          easing: 'easeInOutSine'
        });
        anime({
            targets: card1,
            translateX: 65,
            rotate: 4,
            duration: 400,
            easing: 'easeInOutSine'
          });
        anime({
          targets: card3,
          translateY: 35,
          translateX: -15,
          rotate: 8,
          duration: 400,
          easing: 'easeInOutSine'
        });
      }
    
      let hover3Card2Out = function() {
        this.addEventListener("mouseenter", hover3Card2);
        this.removeEventListener("mouseleave", hover3Card2Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
        const card3 = parent.querySelector('.card_3');
  
        anime({
            targets: card2,
            translateY: 35,
            translateX: 30,
            rotate: -6,
            duration: 400,
            easing: 'easeInOutSine'
          });
          anime({
              targets: card1,
              translateX: 0,
              rotate: 0,
              duration: 400,
              easing: 'easeInOutSine'
            });
          anime({
            targets: card3,
            translateY: 35,
            translateX: -30,
            rotate: 6,
            duration: 400,
            easing: 'easeInOutSine'
          });
      }



      let hover3Card3 = function() {
        this.removeEventListener("mouseenter", hover3Card3);
        this.addEventListener("mouseleave", hover3Card3Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
        const card3 = parent.querySelector('.card_3');
  
        anime({
          targets: card3,
          translateY: 0,
          translateX: -30,
          rotate: 0,
          duration: 400,
          easing: 'easeInOutSine'
        });
        anime({
            targets: card1,
            translateX: -65,
            rotate: -4,
            duration: 400,
            easing: 'easeInOutSine'
          });
        anime({
          targets: card2,
          translateY: 35,
          translateX: 15,
          rotate: -8,
          duration: 400,
          easing: 'easeInOutSine'
        });
      }
    
      let hover3Card3Out = function() {
        this.addEventListener("mouseenter", hover3Card3);
        this.removeEventListener("mouseleave", hover3Card3Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
        const card3 = parent.querySelector('.card_3');
  
        anime({
            targets: card2,
            translateY: 35,
            translateX: 30,
            rotate: -6,
            duration: 400,
            easing: 'easeInOutSine'
          });
          anime({
              targets: card1,
              translateX: 0,
              rotate: 0,
              duration: 400,
              easing: 'easeInOutSine'
            });
          anime({
            targets: card3,
            translateY: 35,
            translateX: -30,
            rotate: 6,
            duration: 400,
            easing: 'easeInOutSine'
          });
      }



      let hover2Card1 = function() {
        this.removeEventListener("mouseenter", hover2Card1);
        this.addEventListener("mouseleave", hover2Card1Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
  
        anime({
          targets: card2,
          translateX: 60,
          rotate: 8,
          duration: 400,
          easing: 'easeInOutSine'
        });
  
        anime({
          targets: card1,
          translateX: -30,
          rotate: -2,
          duration: 400,
          easing: 'easeInOutSine'
        });
  
      }
  
      let hover2Card1Out = function() {
          this.addEventListener("mouseenter", hover2Card1);
          this.removeEventListener("mouseleave", hover2Card1Out);
    
          const parent = this.closest('.events_container');
          const card1 = parent.querySelector('.card_1');
          const card2 = parent.querySelector('.card_2');
    
          anime({
            targets: card2,
            translateX: 50,
            rotate: 6,
            duration: 400,
            easing: 'easeInOutSine'
          });
    
          anime({
            targets: card1,
            translateX: -50,
            rotate: -6,
            duration: 400,
            easing: 'easeInOutSine'
          });
      }


      let hover2Card2 = function() {
        this.removeEventListener("mouseenter", hover2Card2);
        this.addEventListener("mouseleave", hover2Card2Out);
  
        const parent = this.closest('.events_container');
        const card1 = parent.querySelector('.card_1');
        const card2 = parent.querySelector('.card_2');
  
        anime({
          targets: card1,
          translateX: -60,
          rotate: -8,
          duration: 400,
          easing: 'easeInOutSine'
        });
  
        anime({
          targets: card2,
          translateX: 30,
          rotate: 2,
          duration: 400,
          easing: 'easeInOutSine'
        });
  
      }
  
      let hover2Card2Out = function() {
          this.addEventListener("mouseenter", hover2Card2);
          this.removeEventListener("mouseleave", hover2Card2Out);
    
          const parent = this.closest('.events_container');
          const card1 = parent.querySelector('.card_1');
          const card2 = parent.querySelector('.card_2');
    
          anime({
            targets: card2,
            translateX: 50,
            rotate: 6,
            duration: 400,
            easing: 'easeInOutSine'
          });
    
          anime({
            targets: card1,
            translateX: -50,
            rotate: -6,
            duration: 400,
            easing: 'easeInOutSine'
          });
      }



// SLIDER MOBILE

    let slideLeft = function(slider) {
        let index = parseInt(slider.dataset.index);
        const cards = slider.querySelectorAll('.article_card');
        const currentCard = cards[index];
        const bullets = slider.closest('.module-05-events').querySelectorAll('.bullet_nav .bullet');
        console.log(bullets[index]);
        bullets[index].classList.remove('active');

        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }

        const targetCard = cards[index];
        bullets[index].classList.add('active');
        slider.dataset.index = index;

        anime({
            targets: currentCard,
            translateX: [0,-40],
            opacity:[1, 0],
            scale: [1,0.93],
            rotate: [0,-20],
            duration: 400,
            filter: ['drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15))','drop-shadow(0px 8px 30px rgba(0, 0, 0, 0))'],
            easing: 'easeInOutSine',
            complete: function() {
                currentCard.style.zIndex = 3;
                anime({
                    targets: currentCard,
                    translateX: [12,12],
                    translateY: [-30,-30],
                    opacity:[0,1],
                    scale: [0.93,0.93],
                    rotate: [9,6],
                    duration: 400,
                    easing: 'easeInOutSine',
                });
            }
        });
        anime({
            targets: targetCard,
            translateX: [20, 0],
            translateY: [-25, 0],
            scale: [0.93, 1],
            rotate: [3, 0],
            filter: ['drop-shadow(0px 8px 30px rgba(0, 0, 0, 0))','drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15))'],
            duration: 400,
            easing: 'easeInOutSine',
            complete: function() {
                targetCard.style.zIndex = 5;
            }
        });

        if(cards.length == 3) {
            if(index == cards.length-1) {
                index = 0;
            } else {
                index++;
            }
            const nxtCard = cards[index];
            anime({
                targets: nxtCard,
                translateX: [12,20],
                translateY: [-30,-25],
                scale: [0.93, 0.93],
                rotate: [6, 3],
                duration: 400,
                delay: 200,
                easing: 'easeInOutSine',
                complete: function() {
                    nxtCard.style.zIndex = 4;
                }
            });
        }
    }

    let slideRight = function(slider) {
        let index = parseInt(slider.dataset.index);
        const cards = slider.querySelectorAll('.article_card');
        const currentCard = cards[index];
        const bullets = slider.closest('.module-05-events').querySelectorAll('.bullet_nav .bullet');
        console.log(bullets[index]);
        bullets[index].classList.remove('active');

        if(index == cards.length-1) {
            index = 0;
        } else {
            index++;
        }

        const targetCard = cards[index];
        bullets[index].classList.add('active');
        slider.dataset.index = index;

        anime({
            targets: currentCard,
            translateX: [0,-40],
            opacity:[1, 0],
            scale: [1,0.93],
            rotate: [0,-20],
            duration: 400,
            filter: ['drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15))','drop-shadow(0px 8px 30px rgba(0, 0, 0, 0))'],
            easing: 'easeInOutSine',
            complete: function() {
                currentCard.style.zIndex = 3;
                anime({
                    targets: currentCard,
                    translateX: [12,12],
                    translateY: [-30,-30],
                    opacity:[0,1],
                    scale: [0.93,0.93],
                    rotate: [9,6],
                    duration: 400,
                    easing: 'easeInOutSine',
                });
            }
        });
        anime({
            targets: targetCard,
            translateX: [20, 0],
            translateY: [-25, 0],
            scale: [0.93, 1],
            rotate: [3, 0],
            filter: ['drop-shadow(0px 8px 30px rgba(0, 0, 0, 0))','drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15))'],
            duration: 400,
            easing: 'easeInOutSine',
            complete: function() {
                targetCard.style.zIndex = 5;
            }
        });

        if(cards.length == 3) {
            if(index == cards.length-1) {
                index = 0;
            } else {
                index++;
            }
            const nxtCard = cards[index];
            anime({
                targets: nxtCard,
                translateX: [12,20],
                translateY: [-30,-25],
                scale: [0.93, 0.93],
                rotate: [6, 3],
                duration: 400,
                delay: 200,
                easing: 'easeInOutSine',
                complete: function() {
                    nxtCard.style.zIndex = 4;
                }
            });
        }
    }

    initFunc();
  };
}
export { Module_05_Events }

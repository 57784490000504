import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Contact {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.dropClick = document.querySelectorAll('.template-contact .drop_click');
    };

    let registerEvents = () => {
        for (let i = 0; i < cache.dropClick.length; i++) {
            cache.dropClick[i].addEventListener('click',toggleDrop);
        }
    };

    let toggleDrop = function() {
        const drop = this.closest('.hours').querySelector('.drop_hours');
        
        if(drop.classList.contains('open')) {
            this.classList.remove('open');
            drop.classList.remove('open');

            anime({
                targets: drop,
                duration: 400,
                height: 0,
                easing: 'easeInQuad',
                complete: function() {
                    drop.style.display = "none";
                }
            });
        } else {
            this.classList.add('open');
            drop.classList.add('open');

            drop.style.display = "block";
            drop.style.height = "auto";
            const height = drop.offsetHeight;
            drop.style.height = 0;

            anime({
                targets: drop,
                duration: 400,
                height: height,
                easing: 'easeInQuad',
            });
        }
    }

    initFunc();
  };
}
export { Contact }